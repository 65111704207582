import { isSemanticSearchCalledAtom } from '@components/listings-search-bar/atoms';
import * as ExperimentActions from '@redux/experiments';
import { selectAssignment } from '@redux/experiments/selectors';
import { capacityFilterSelector } from '@redux/experiments/selectors/capacity-filter';
import { expectedValueSortSelector } from '@redux/experiments/selectors/expected-value-sort';
import { learnToRankSelector } from '@redux/experiments/selectors/learn-to-rank';
import { listingsSectionsSelector } from '@redux/experiments/selectors/listings-sections';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { quickFiltersSelector } from '@redux/experiments/selectors/quick-filters';
import { recentlyViewedAssignmentSelector } from '@redux/experiments/selectors/recently-viewed';
import { spotlightAdSelector } from '@redux/experiments/selectors/spotlight-ad';
import { useAppSelector } from '@redux/hooks';
import { experiments, tkHost } from '@settings';
import { useAtomValue } from 'jotai';
import React, { type FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import buildLinkedDataFromApiData from '../../../helpers/linkedData';
import MetaContent from '../../components/shared/HelmetHeader/HelmetHeader';
import { RFQModalContextProvider } from '../../contexts/RFQModalContext/RFQModalContext';
import { WeddingsContextProvider } from '../../contexts/WeddingsContext/WeddingsContext';
import { setCookieLocation } from '../../utils/cookie/location';
import WrappedVendorsSearch from './containers/WrappedVendorsSearch';
import * as util from './util';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & RouteComponentProps;

const VendorsSearchContainer: FunctionComponent<Props> = (props) => {
	const {
		category,
		categoryFilters,
		vendorLocation,
		history: {
			location: { pathname },
		},
		isPermitted,
		reportServerSideExperiment,
		seo,
	} = props;

	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const seoCategory = util.categoryText(category, categoryFilters);

	const refreshResultsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['refreshResults']),
	);

	const mobileMapViewAssignment = useAppSelector((state) =>
		mobileMapViewAssignmentSelector(state),
	);

	const expectedValueSortAssignment = useAppSelector((state) =>
		expectedValueSortSelector(state),
	);

	const recentlyViewedAssignment = useAppSelector((state) =>
		recentlyViewedAssignmentSelector(state),
	);

	const listingsSectionsAssignment = useAppSelector((state) =>
		listingsSectionsSelector(state),
	);

	const capacityFilterAssignment = useAppSelector((state) =>
		capacityFilterSelector(state),
	);

	const learnToRankAssignment = useAppSelector((state) =>
		learnToRankSelector(state),
	);

	const quickFiltersAssignment = useAppSelector((state) =>
		quickFiltersSelector(state),
	);

	const spotlightAdAssignment = useAppSelector((state) =>
		spotlightAdSelector(state),
	);

	useEffect(() => {
		window.UnionConsentManagement.onConsentedToPersonalisation(() => {
			setCookieLocation(vendorLocation);
		});

		if (refreshResultsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Refresh results Assignment: ', refreshResultsAssignment);
			reportServerSideExperiment(experiments.refreshResults);
		}

		if (mobileMapViewAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Mobile Map View Assignment: ', mobileMapViewAssignment);
			reportServerSideExperiment(experiments.mobileMapView);
		}

		if (expectedValueSortAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Expected value sort assignment: ',
				expectedValueSortAssignment,
			);
			reportServerSideExperiment(experiments.expectedValue);
		}

		if (recentlyViewedAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Recently viewed assignment: ', recentlyViewedAssignment);
			reportServerSideExperiment(experiments.recentlyViewed);
		}

		if (listingsSectionsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Listings Sections assignment: ', listingsSectionsAssignment);
			reportServerSideExperiment(experiments.listingsSections);
		}

		if (capacityFilterAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Capacity Filter assignment: ', capacityFilterAssignment);
			reportServerSideExperiment(experiments.capacityFilter);
		}

		if (learnToRankAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Learn To Rank assignment: ', learnToRankAssignment);
			reportServerSideExperiment(experiments.learnToRank);
		}

		if (quickFiltersAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Quick Filters assignment: ', quickFiltersAssignment);
			reportServerSideExperiment(experiments.quickFilters);
		}

		if (spotlightAdAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Spotlight Ad assignment: ', spotlightAdAssignment);
			reportServerSideExperiment(experiments.spotlightAd);
		}
	}, [
		mobileMapViewAssignment,
		refreshResultsAssignment,
		reportServerSideExperiment,
		vendorLocation,
		expectedValueSortAssignment,
		recentlyViewedAssignment,
		listingsSectionsAssignment,
		capacityFilterAssignment,
		learnToRankAssignment,
		quickFiltersAssignment,
		spotlightAdAssignment,
	]);

	return (
		<>
			<RFQModalContextProvider>
				<WeddingsContextProvider>
					<MetaContent
						buildLinkedData={buildLinkedDataFromApiData}
						canonical={seo.canonicalUrl}
						description={`Learn more about ${seoCategory.toLowerCase()} in ${
							vendorLocation.city
						} on The Knot.
            Find, research and contact wedding professionals on The Knot, featuring reviews
            and info on the best wedding vendors.`}
						landingPage="listings"
						next={seo.nextUrl}
						ogImage={util.ogImage(category.code)}
						pathname={`${tkHost}${pathname}`}
						prev={seo.previousUrl}
						title={util.pageTitle(vendorLocation, seoCategory, category.code)}
						noIndex={!isPermitted || isSemanticSearchCalled}
					/>
					<WrappedVendorsSearch />
				</WeddingsContextProvider>
			</RFQModalContextProvider>
		</>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	category: state.category,
	categoryFilters: state.filters.categoryFilters,
	isPermitted: state.search.isPermitted,
	seo: state.seo,
	vendorLocation: state.location,
});

export const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const enhance = compose(
	connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhance(VendorsSearchContainer);
