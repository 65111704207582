import { get } from 'lodash';
import { Track } from 'types/analytics';
import { getAppliedStartingPriceFilterOptions } from '../components/StartingPriceFilter/utils';
import {
	buildCategoryFilters,
	settingsFilter,
	trackFilterVendors,
} from '../helpers';

export const prepareFilter = (
	filters: Search.FilterDetails[],
	filterCategory: string,
): Search.FilterDetails | undefined => {
	const filterCopy = filters.find((f) => f.name === filterCategory);
	if (filterCategory === 'Wedding Venue Amenities' && filterCopy) {
		filterCopy.filterOptions = filterCopy.filterOptions.filter(
			(o) =>
				o.name !== 'Outdoor Event Space' && o.name !== 'Covered Outdoors Space',
		);
		return filterCopy;
	}

	if (filterCategory === 'Starting Price Range' && filterCopy) {
		const affordableValue = {
			slug: '0-999',
			term: '0-999',
		};
		filterCopy.filterOptions.forEach((f, index) => {
			if (f.id === 'bf895e5e-585b-4b5b-b8c9-e1cc0331dee6') {
				filterCopy.filterOptions[index] = {
					...f,
					singular: affordableValue,
					plural: affordableValue,
				};
			}
		});
	}

	return filters.find((f) => f.name === filterCategory);
};

export const findCategoryData = (
	categories: string[],
	filters: Search.FilterDetails[],
): Search.FilterDetails[] => {
	const transformed: Search.FilterDetails[] = [];
	categories.forEach((category) => {
		const filter = filters.filter((f) => f.name === category);
		if (filter.length > 0) {
			transformed.push(filter[0]);
		}
	});
	return replaceSettingsFilterOptions(transformed, settingsFilter);
};

export const replaceSettingsFilterOptions = (
	filters: Search.FilterDetails[],
	newSettingsFilter: Search.FilterDetails,
	isInExperiment?: boolean,
) => {
	const modifiedFilters = filters.map((filter) => {
		if (filter.name === 'Settings') {
			const modifiedFilterOptions = filter.filterOptions.filter((option) => {
				return !newSettingsFilter.filterOptions.some(
					(newFilters) => newFilters.id === option.id,
				);
			});
			return {
				...filter,
				filterOptions: [
					...modifiedFilterOptions,
					...newSettingsFilter.filterOptions,
				],
			};
		}
		if (filter.name === 'Guest Capacity' && isInExperiment) {
			return {
				...filter,
				isSingleSelect: false,
			};
		}
		return filter;
	});
	return modifiedFilters;
};

export const lookUpAndTransform = (
	target: string,
	flag: 'id' | 'name',
	categoryFilters: Search.FilterDetails[],
): Search.AppliedFilterPillProps | null => {
	let match = null;
	categoryFilters.forEach((filter) => {
		filter.filterOptions.forEach((option) => {
			if (option[flag] === target) {
				match = {
					categorySlug: filter.slug,
					id: option.id,
					name: option.name,
					value: option.singular.slug,
				};
			}
		});
	});
	return match;
};

export const selectedFiltersToKeep = (
	selected: string[],
	appliedFilters: Search.AppliedFilterPillProps[],
): string[] => {
	// keep if item is in selected[] and applied[]
	const keep: string[] = [];
	selected.forEach((item) => {
		if (appliedFilters.some((filter) => filter.id === item)) {
			keep.push(item);
		}
	});
	return keep;
};

interface SaveSidebarOptions {
	addAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.AddAppliedFilterPillOptions;
	commitAppliedFilterPillChanges: () => Search.Actions.CommitAppliedFilterPillChanges;
	modifyApplied: (
		applied: Search.AppliedFilterPillProps[],
	) => Search.Actions.ModifyApplied;
	removeAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.RemoveAppliedFilterPillOptions;
	setIsSidebarOpen: (bool: boolean) => Search.Actions.SetIsSidebarOpen;
	track: Track;
	updateSeoFilterValue: (seoFilter?: string) => Seo.UpdateSeoFilterValueAction;
}

interface SaveData {
	applied: Search.AppliedFilterPillProps[];
	appliedFilters: Search.AppliedFilterPillProps[];
	category: Redux.Category;
	categoryFilters: Search.FilterDetails[];
	flattenSidebarOptions: string[];
	location: Redux.Location;
	selected: string[];
}
// save button on <Sidebar>
export const saveSidebarOptions = (
	fn: SaveSidebarOptions,
	data: SaveData,
): void => {
	if (data.appliedFilters.length > 0 || data.selected.length > 0) {
		fn.setIsSidebarOpen(false);

		const transformSelected = data.selected
			.map((selection) =>
				lookUpAndTransform(selection, 'id', data.categoryFilters),
			)
			.filter((item) => item !== null) as Search.AppliedFilterPillProps[];

		const startingPriceFilter = data.selected.find((f) =>
			f.includes('starting_price'),
		);
		if (startingPriceFilter) {
			const [_, minPrice, maxPrice] = startingPriceFilter.split('-');
			getAppliedStartingPriceFilterOptions(minPrice, maxPrice).forEach(
				(option) => {
					transformSelected.push(option);
				},
			);
		}
		// if in transformedSelected but not appliedFilters then add item
		const add: Search.AppliedFilterPillProps[] = [];
		transformSelected.forEach((select) => {
			if (
				select.id.includes('starting-price') ||
				data.appliedFilters.every((filter) => filter.id !== select.id)
			) {
				add.push(select);
			}
		});

		if (add.length > 0) {
			fn.addAppliedFilterPillOptions(add);
		}

		// if in appliedFilters but not transformedSelected then remove item
		const remove: Search.AppliedFilterPillProps[] = [];
		data.appliedFilters.forEach((filter) => {
			if (
				(filter.id.includes('starting-price') &&
					add.some((select) => select.id === filter.id)) ||
				transformSelected.every((select) => select.id !== filter.id)
			) {
				remove.push(filter);
			}
		});
		if (remove.length > 0) {
			fn.removeAppliedFilterPillOptions(remove);
		}

		const stagedFilters = {
			add,
			remove,
		} as Search.StagedFilterPills;

		const appliedCategoryFilters = buildCategoryFilters(
			data.appliedFilters,
			stagedFilters,
		);

		trackFilterVendors({
			track: fn.track,
			categoryCode: data.category.code,
			categoryFilters: appliedCategoryFilters,
			categoryName: data.category.name,
			location: data.location,
			trackAction: 'save selected filters',
		});

		fn.modifyApplied(transformSelected);
		fn.commitAppliedFilterPillChanges();
		fn.updateSeoFilterValue();
	}
};
