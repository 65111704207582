import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { useMemo } from 'react';

export const useShouldShowPricingFilter = () => {
	const featureFlags = useFeatureFlags();

	return useMemo(
		() => featureFlags[FEATURE_FLAGS.LISTINGS_PRICING],
		[featureFlags[FEATURE_FLAGS.LISTINGS_PRICING]],
	);
};
