import { CATEGORY_FACETS } from '@constants/categories';
import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import { H3 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { FacetLink } from './FacetLink';
import Styles from './VenueFacetLinks.styles.scss';

export const buildBase = (path: string) => {
	const [, base, category] = path.split('/');
	return base && category ? `/${base}/${category}` : '';
};

export const VenueFacetLinks = () => {
	const city = useAppSelector((state) => state.location.city);
	const sort = useAppSelector((state) => state.search.sort);
	const path = useAppSelector((state) => state.requestInfo.path);
	const categoryCode = useAppSelector((state) => state.category.code);
	const sortSlug = sort ? `&sort=${sort}` : '';
	const base = buildBase(path);
	const categoryFacet = CATEGORY_FACETS[categoryCode];

	if (!categoryFacet || !base) {
		return null;
	}

	return (
		<>
			<Row>
				<Column xs="12">
					<H3 className={Styles.heading}>
						Explore other {categoryFacet.name.toLowerCase()} in {city}
					</H3>
				</Column>
			</Row>
			<Row>
				<Column xs="12" className={Styles.links}>
					{categoryFacet.facets.map((link, index) => (
						<FacetLink
							key={link.facet}
							label={link.label}
							href={`${base}?${link.facet}${sortSlug}`}
							isLastItem={index === categoryFacet.facets.length - 1}
						/>
					))}
				</Column>
			</Row>
		</>
	);
};
