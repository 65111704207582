import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { useMemo } from 'react';

export const useListingDebugger = () => {
	const featureFlags = useFeatureFlags();

	return useMemo(
		() => featureFlags[FEATURE_FLAGS.LISTING_DEBUGGER],
		[featureFlags[FEATURE_FLAGS.LISTING_DEBUGGER]],
	);
};
