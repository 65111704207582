import { Match } from '@hooks/use-search-by-bounding-box';
import useIsSticky from '@hooks/useIsSticky';
import * as VendorImpressionThunks from '@redux/vendorImpressions/thunks';
import { compose } from '@xo-union/react-css-modules';
import { Row } from '@xo-union/tk-component-grid';
import React, { FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { seoFilter } from '../../../../../helpers/seo';
import * as SearchActions from '../../../../redux/search/actions';
import * as SeoActions from '../../actions/seo';
import AppliedFilters from './containers/AppliedFilters';
import FilterPills from './containers/FilterPills';
import FilterSidebar from './containers/FilterSidebar';
import {
	buildCategoryFilters,
	createClassForPillRow,
	updateHistory,
} from './helpers';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

interface DispatchProps {
	receiveFilterPills: (
		options: SearchActions.ReceiveFilterPillsOptions,
	) => Search.Actions.ReceiveFilterPills;
	resetStagedFilterPillChanges: () => Search.Actions.ResetStagedFilterPillChanges;
	setFilterPillLimit: (
		limit: Search.FilterPillLimit,
	) => Search.Actions.SetFilterPillLimit;
	transmitImpressions: (transmitMinimum?: number) => void;
	updateSeoFilterValue: (
		seoFilterValue: string | undefined,
	) => Seo.UpdateSeoFilterValueAction;
}

interface MapViewButtonProps {
	match: Match;
	searchLocation: string;
	showMapView: boolean;
}

export type Props = StateProps & DispatchProps & MapViewButtonProps;

export const FilterPillsWrapper: FC<Props> = ({
	appliedFilterMap,
	appliedFilterPills,
	categoryCode,
	commitStagedFilters,
	filterPillLimit,
	filters,
	receiveFilterPills,
	resetStagedFilterPillChanges,
	sort,
	setFilterPillLimit,
	stagedFilters,
	transmitImpressions,
	updateSeoFilterValue,
	viewport,
	vendorImpressions,
	match,
	searchLocation,
	showMapView,
}) => {
	const location = useLocation();
	const { pathname, search } = location;
	const { isMobile, lessThan, greaterThan } = viewport;
	const history = useHistory();
	const stickyRef = useRef(null);
	const isSticky = useIsSticky(stickyRef, isMobile);

	useEffect(() => {
		if (commitStagedFilters) {
			transmitImpressions(1);
			const appliedCategoryFilters = buildCategoryFilters(
				appliedFilterPills,
				stagedFilters,
			);

			updateHistory({
				isClearFilter: false,
				categoryFilters: appliedCategoryFilters,
				pathname,
				search,
				sort,
				history,
			});
			resetStagedFilterPillChanges();
			animateScroll.scrollToTop();
		}
	}, [commitStagedFilters]);

	useEffect(() => {
		if (isSticky || showMapView) {
			document.body.classList.add(Styles.stickyBody);
		} else {
			document.body.classList.remove(Styles.stickyBody);
		}

		return () => document.body.classList.remove(Styles.stickyBody);
	}, [isSticky, showMapView]);

	useEffect(() => {
		// Switching from desktop to mobile
		if (isMobile || lessThan.small) {
			receiveFilterPills({
				categoryCode,
				filters: filters.filters,
				mobileUI: true,
			});
			return;
		}

		// Switching from mobile to small desktop
		if (filterPillLimit === 3) {
			receiveFilterPills({
				categoryCode,
				filters: filters.filters,
				mobileUI: false,
				desktopLimit: 5,
			});
			return;
		}

		// Switching from small desktop to large desktop
		if (greaterThan.large) {
			setFilterPillLimit(6);
			return;
		}

		// Switching from large desktop to small desktop
		setFilterPillLimit(5);
	}, [viewport]);

	useEffect(() => {
		const updatedSeoFilter = seoFilter(categoryCode, appliedFilterMap);
		updateSeoFilterValue(updatedSeoFilter);
	}, [appliedFilterMap, categoryCode, updateSeoFilterValue]);

	return (
		<>
			<div
				ref={stickyRef}
				className={createClassForPillRow(isSticky, showMapView)}
			>
				<FilterPills
					match={match}
					searchLocation={searchLocation}
					showMapView={showMapView}
					isSticky={isSticky}
				/>
			</div>
			<Row
				classes={compose({
					row: appliedFilterPills.length
						? Styles.container
						: Styles.containerNoAppliedFilters,
				})}
			>
				<AppliedFilters />
			</Row>
			<FilterSidebar />
		</>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	appliedFilterPills: state.search.filterPills.applied,
	appliedFilterMap: state.filters.appliedFilters,
	categoryCode: state.category.code,
	filters: state.search.filters,
	filterPillLimit: state.search.filterPills.limit,
	stagedFilters: state.search.filterPills.staged,
	commitStagedFilters: state.search.filterPills.staged.commitChanges,
	sort: state.search.sort,
	viewport: state.viewport,
	vendorImpressions: state.vendorImpressions,
});

export const mapDispatchToProps: DispatchProps = {
	receiveFilterPills: SearchActions.receiveFilterPills,
	resetStagedFilterPillChanges: SearchActions.resetStagedFilterPillChanges,
	setFilterPillLimit: SearchActions.setFilterPillLimit,
	transmitImpressions: VendorImpressionThunks.transmitImpressions,
	updateSeoFilterValue: SeoActions.updateSeoFilterValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPillsWrapper);
